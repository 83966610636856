import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { AdaptiveModal } from "components/molecules/modal/components/adaptive-modal/adaptive-modal.component";
import { ModalBody } from "components/molecules/modal/components/modal-body/modal-body.component";
import { ModalDesktopHeader } from "components/molecules/modal/components/modal-desktop-header/modal-desktop-header.component";
import { ModalMobileFooter } from "components/molecules/modal/components/modal-mobile-footer/modal-mobile-footer.component";
import { UserInfo } from "./components/user-info/user-info.component";
import { testIds, UserInfoModalProps } from "./user-info-modal.interface";
import { StyledContent } from "./user-info-modal.styled";

export const UserInfoModal = ({
    opened,
    onClose,
    userId,
}: UserInfoModalProps) => {
    const { t } = useTranslation("users");

    return (
        <AdaptiveModal
            testId={testIds.userInfoModal}
            height={620}
            opened={opened}
            onClose={onClose}
        >
            <ModalBody data-testid={testIds.userInfoModalBody} width={500}>
                <ModalDesktopHeader
                    onClose={onClose}
                    title={t("title.userInfo", "User info")}
                />
                <StyledContent>
                    {opened && <UserInfo userId={userId} />}
                </StyledContent>
                <ModalMobileFooter>
                    <Button
                        showTitle
                        width={72}
                        data-testid={testIds.userInfoModalBackButton}
                        onClick={onClose}
                    >
                        {t("button.back", "Back")}
                    </Button>
                </ModalMobileFooter>
            </ModalBody>
        </AdaptiveModal>
    );
};
