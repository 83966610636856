import React from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "next-i18next";

import { StyledTableBlock, StyledUsersContent } from "./users.styled";
import { Table } from "components/molecules/table/table.component";
import { SortingButton } from "components/molecules/table/components/sorting-button/sorting-button.component";
import { breakpoints } from "lib/breakpoints";
import { TableColumn } from "components/molecules/table/table.interface";
import { HorizontalScrollableBlockWithShadow } from "components/atoms/horizontal-scrollable-block-with-shadow/horizontal-scrollable-block-with-shadow.component";
import {
    UsersProps,
    UsersTableColumns,
    mobileColumns,
} from "./users.interface";

export const Users = React.memo(
    ({ usersList, setSortBy, sortBy }: UsersProps) => {
        const { t } = useTranslation("users");
        const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

        const columns: TableColumn<UsersTableColumns>[] = [
            {
                name: "avatar",
                render: () => "",
            },
            {
                name: "username",
                render: () => (
                    <SortingButton
                        name="username"
                        label={t("column.userName", "User name")}
                        filterBy={setSortBy}
                        filterInfo={sortBy}
                    />
                ),
            },
            {
                name: "role",
                render: () => (
                    <SortingButton
                        name="role"
                        label={t("column.role", "Role")}
                        filterBy={setSortBy}
                        filterInfo={sortBy}
                    />
                ),
            },
            {
                name: "status",
                render: () => (
                    <SortingButton
                        name="status"
                        label={t("column.status", "Status")}
                        filterBy={setSortBy}
                        filterInfo={sortBy}
                    />
                ),
            },
            {
                name: "actions",
                render: () => <></>,
            },
        ];

        return (
            <StyledUsersContent>
                <HorizontalScrollableBlockWithShadow>
                    <StyledTableBlock>
                        <Table
                            showHeadInMobile={false}
                            data={usersList}
                            columns={isMobile ? mobileColumns : columns}
                        />
                    </StyledTableBlock>
                </HorizontalScrollableBlockWithShadow>
            </StyledUsersContent>
        );
    },
);

Users.displayName = "Users";
