import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { AggregateService } from "lib/api/api";
import { V1DeleteNotConfirmedUsersResponse } from "lib/api/common/data-contracts";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<
    V1DeleteNotConfirmedUsersResponse,
    HttpErrorType,
    string
>;

export function useDeleteNotConfirmedUserMutation(options?: Options) {
    return useMutation(async (args: string) => {
        return (
            await AggregateService.uiBackendServiceDeleteNotConfirmedUsers(args)
        ).data;
    }, options);
}
