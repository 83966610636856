import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";

import { breakpoints } from "lib/breakpoints";
import { getUsername } from "lib/utils/entities/users/get-username.utilit";
import { DeleteUserInfoType, testIds } from "./users.interface";
import { UserInfoMenuButton } from "components/entities/users/organisms/user-info-menu-button/user-info-menu-button.component";
import { UserStatusLabel } from "components/entities/users/molecules/user-status-label/user-status-label.component";
import { FilterState } from "components/entities/users/organisms/users-filter/users-filter.interface";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { usersTableColumns } from "./users.constants";
import { useSortState } from "components/molecules/table/use-sort-state.hook";
import { useGetUsersQuery } from "lib/api/hooks/use-get-users-query";
import { NOT_ASSIGNED_SUB_ROLE_LABEL } from "lib/constants/not-assigned-sub-role";
import { useUserType } from "lib/hooks/use-user-type.hook";
import { UsersTableColumns } from "components/entities/users/organisms/users/users.interface";
import { TableRow } from "components/molecules/table/table.interface";
import { Authv1User } from "lib/api/common/data-contracts";
import {
    useSyncWithQueryParams,
    parseQueryStringByKey,
} from "lib/utils/query-string";
import {
    StyledAvatarBlock,
    StyledRole,
    StyledUserInfoBlock,
    StyledUsername,
} from "./users.styled";
import {
    getUserData,
    mappingByFilter,
    mappingBySearchWord,
    sortingFn,
} from "./users.utilit";

export const useUserTemplate = () => {
    const isDesktop = useMediaQuery(
        `(min-width: ${`${breakpoints.xl + 1}px`})`,
    );
    const userType = useUserType();

    const usersQuery = useGetUsersQuery();
    const usersData = orderBy(
        getUserData(usersQuery.data),
        ["created_at"],
        ["asc"],
    );

    const [userStatusModalData, setUserStatusModalData] = useState<{
        isOpen: boolean;
        username: string;
        id: string;
        isBanned: boolean;
    }>({ username: "", id: "", isBanned: false, isOpen: false });

    const [deleteUserInfo, setDeleteUserInfo] = useState<DeleteUserInfoType>();
    const [changeRoleData, setChangeRoleData] = useState<Authv1User>();
    const { sortState, setSortState } = useSortState();
    const [searchInputValue, setSearchInputValue] = useState("");
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
    const [isUserInfoModalOpen, setIsUserInfoModalOpen] =
        useState<boolean>(false);
    const [userId, setUserId] = useState("");
    const [reset2FAModalData, setReset2FAModalData] = useState("");

    const [filterActiveData, setFilterActiveData] = useState<FilterState>(
        () => (parseQueryStringByKey("filter") || {}) as FilterState,
    );
    useSyncWithQueryParams({ filter: filterActiveData });

    const [rightModalActiveStatus, { open, close }] = useDisclosure(isDesktop);

    const handleMappingBySearchWord = useCallback(mappingBySearchWord, []);

    useLayoutEffect(() => {
        if (isDesktop) {
            open();
        }
    }, [close, isDesktop, open]);

    const usersList = useMemo(() => {
        return usersData
            ?.filter((user: Authv1User) =>
                mappingByFilter(user, filterActiveData),
            )
            ?.filter((user: Authv1User) =>
                handleMappingBySearchWord(user, searchInputValue),
            )
            ?.sort((a, b) => sortingFn(a, b, sortState))
            ?.map<TableRow<UsersTableColumns>>((user: Authv1User) => ({
                avatar: {
                    name: usersTableColumns.AVATAR,
                    render: () => (
                        <StyledAvatarBlock>
                            {/* TODO: WILL BE UPDATED */}
                            {/* <Avatar
                            avatarImageUrl="https://i.postimg.cc/HL7Zzy3S/Rectangle-2138-1.png"
                            size={30}
                        /> */}
                            <Icon name={Icons.defaultAvatarSvg} />
                        </StyledAvatarBlock>
                    ),
                },
                data: {
                    id: user.id,
                },
                username: {
                    name: usersTableColumns.USERNAME,
                    render: () => getUsername(user),
                },
                role: {
                    name: usersTableColumns.ROLE,
                    render: () => (
                        <span data-testid={`${testIds.userRole}-${user.id}`}>
                            {user.sub_role_title || NOT_ASSIGNED_SUB_ROLE_LABEL}
                        </span>
                    ),
                },
                status: {
                    name: usersTableColumns.STATUS,
                    render: () => (
                        <UserStatusLabel
                            isBanned={user?.is_banned}
                            isConfirmed={user?.is_confirmed}
                            testId={`${testIds.userStatus}-${user.id}`}
                        />
                    ),
                },
                actions: {
                    name: usersTableColumns.ACTIONS,
                    render: () => (
                        <UserInfoMenuButton
                            setChangeRoleData={setChangeRoleData}
                            setDeleteUserInfo={setDeleteUserInfo}
                            openUserInfoModal={setIsUserInfoModalOpen}
                            setUserId={setUserId}
                            userInfo={user}
                            setUserStatusModalData={setUserStatusModalData}
                            setReset2FAModalData={setReset2FAModalData}
                        />
                    ),
                },
                userInfo: {
                    name: usersTableColumns.USER_INFO,
                    render: () => (
                        <StyledUserInfoBlock>
                            <StyledUsername>{getUsername(user)}</StyledUsername>
                            <StyledRole
                                data-testid={`${testIds.userRole}-${user.id}`}
                            >
                                {user.sub_role_title ||
                                    NOT_ASSIGNED_SUB_ROLE_LABEL}
                            </StyledRole>
                        </StyledUserInfoBlock>
                    ),
                },
            }));
    }, [
        usersData,
        filterActiveData,
        handleMappingBySearchWord,
        searchInputValue,
        sortState,
    ]);

    return {
        rightModalActiveStatus,
        close,
        open,
        setFilterActiveData,
        filterActiveData,
        userId,
        isUserInfoModalOpen,
        setIsUserInfoModalOpen,
        deleteUserInfo,
        setDeleteUserInfo,
        isNewUserModalOpen,
        setIsNewUserModalOpen,
        searchInputValue,
        setSearchInputValue,
        changeRoleData,
        setChangeRoleData,
        userStatusModalData,
        setUserStatusModalData,
        reset2FAModalData,
        setReset2FAModalData,
        usersList,
        setSortState,
        sortState,
        usersQuery,
        userType,
    };
};
