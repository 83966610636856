import { DeleteUserInfoType } from "components/templates/users/users.interface";
import { Authv1User } from "lib/api/common/data-contracts";

export type UserInfoMenuButtonProps = {
    userInfo: Authv1User;
    setChangeRoleData: (data: Authv1User) => void;
    setDeleteUserInfo: (data?: DeleteUserInfoType) => void;
    openUserInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    setUserId: (userId: string) => void;
    setReset2FAModalData: (id: string) => void;
    setUserStatusModalData: React.Dispatch<
        React.SetStateAction<{
            username: string;
            id: string;
            isBanned: boolean;
            isOpen: boolean;
        }>
    >;
};

export const REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY =
    "toast.successRegistrationLink";
export const REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY =
    "toast.errorRegistrationLink";

export const testIds = {
    sendInvitationsAgain: "send-invitations-again",
    changeRole: "change-role",
    activate: "activate",
    deactivate: "deactivate",
    setUpAccess: "set-up-access",
    delete: "delete",
    userInfo: "user-info",
    configure2fa: "configure-2fa",
    resendTokenSuccessToast: "resend-token-success-toast",
    userActionsMenu: "user-actions-menu",
};
