export type DeleteUserInfoType = { id: string; username: string };

export const USERS_FALLBACK_ERROR_MESSAGE =
    "Something went wrong. We were unable to upload the data. Try again or contact your administrator.";
export const USERS_FALLBACK_ERROR_MESSAGE_KEY =
    "users:description.loadUsersFallbackMessage";

export const testIds = {
    userStatus: "user-status",
    userRole: "user-role",
    usersTabletFilterButton: "users-tablet-filter-button",
    createNewUserButton: "create-new-user-button",
    usersSearchInputMobile: "users-search-input-mobile",
};
