import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";

import { AdaptiveModal } from "components/molecules/modal/components/adaptive-modal/adaptive-modal.component";
import { ModalDesktopHeader } from "components/molecules/modal/components/modal-desktop-header/modal-desktop-header.component";
import { ScrollableModalBody } from "components/molecules/modal/components/modal-body/scrollable-modal-body.component";
import { ModalMobileHeader } from "components/molecules/modal/components/modal-mobile-header/modal-mobile-header.component";
import { ModalFooter } from "components/molecules/modal/components/modal-footer/modal-footer.component";
import { colors } from "lib/palette";
import { CreateUserForm } from "components/entities/users/organisms/create-user-form/create-user-form.component";
import { UserType } from "lib/constants/user-type";
import { userState } from "lib/recoil/atoms/user.atom";
import { IFormState } from "lib/types/form";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { successToast } from "lib/utils/toast";
import {
    StyledFormBlock,
    StyledInfoBlock,
    StyledText,
} from "./create-new-user-modal.styled";
import {
    CREATE_NEW_USER_SUCCESS_TOAST_MESSAGE_KEY,
    CreateNewUserModalProps,
    testIds,
} from "./create-new-user-modal.interface";

export const CreateNewUserModal = ({
    opened,
    onClose,
}: CreateNewUserModalProps) => {
    const { t } = useTranslation("users");

    const queryClient = useQueryClient();

    const authUserData = useRecoilValue(userState);

    const submitRef = useRef<HTMLInputElement>(null);

    const [formState, setFormState] = useState<IFormState>({
        isReady: false,
        isLoading: false,
    });

    const onCreateUserSuccessHandler = async () => {
        await queryClient.invalidateQueries([useGetUsersQueryKey]);
        onClose();
        successToast(
            t(
                CREATE_NEW_USER_SUCCESS_TOAST_MESSAGE_KEY,
                "Registration link was sent to the email.",
            ),
            {
                className: testIds.createUserSuccessToast,
            },
        );
    };

    return (
        <AdaptiveModal
            height={530}
            opened={opened}
            onClose={onClose}
            testId={testIds.createUserModal}
        >
            <ModalDesktopHeader
                title={t("title.createUser", "Create user")}
                onClose={onClose}
            />
            <ScrollableModalBody
                width={560}
                data-testid={testIds.createUserModalBody}
            >
                <ModalMobileHeader
                    title={t("title.createUser", "Create user")}
                    centered
                />

                <StyledInfoBlock>
                    <StyledText
                        align="center"
                        size={14}
                        weight={600}
                        color={colors.black_1}
                    >
                        {t(
                            "description.createUser",
                            "Enter the e-mail of the user you want to add to your company and select the role from which he will participate in the system.",
                        )}
                    </StyledText>
                </StyledInfoBlock>
                <StyledFormBlock>
                    <CreateUserForm
                        withSubRole
                        onSuccess={onCreateUserSuccessHandler}
                        setFormState={setFormState}
                        ref={submitRef}
                        userType={UserType.PROVIDER}
                        providerId={authUserData?.auth.user.vendor_id}
                    />
                </StyledFormBlock>
            </ScrollableModalBody>

            <ModalFooter
                firstButtonWidth={115}
                secondButtonWidth={87}
                isLoading={formState.isLoading}
                firstButtonText={t("button.createUser", "Create user")}
                onFirstButtonClick={() => submitRef.current?.click()}
                isFirstButtonDisabled={!formState.isReady}
                firstButtonTestId={testIds.createUserModalSubmitButton}
                showSecondButtonOnMobileOnly
                onSecondButtonClick={onClose}
                secondButtonTestId={testIds.createUserModalCancelButton}
            />
        </AdaptiveModal>
    );
};
