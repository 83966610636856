export type UserStatusWarningModalProps = {
    isOpen: boolean;
    close: () => void;
    userData: {
        username: string;
        id: string;
        isBanned: boolean;
        isOpen: boolean;
    };
};

export const testIds = {
    deactivateUserButton: "deactivate-user-button",
    activateUserButton: "activate-user-button",
    changeUserStatusModalCancelButton: "change-user-status-modal-cancel-button",
    activateUserSuccessToast: "activate-user-success-toast",
    deactivateUserSuccessToast: "deactivate-user-success-toast",
    userStatusWarning: "user-status-warning",
};
