import { Authv1User, V1CombinedUserItem } from "lib/api/common/data-contracts";
import { ACTIVE, INACTIVE, WAITING } from "lib/constants/status";
import { usersTableColumns } from "./users.constants";
import {
    SortingInfo,
    SortingOrder,
} from "components/molecules/table/components/sorting-button/sorting-button.interface";

export const mappingByFilter = (
    user: Authv1User,
    filterData: Record<string, string[]>,
) => {
    let activeStatus;

    if (!user.is_confirmed) {
        activeStatus = WAITING;
    } else if (user.is_banned) {
        activeStatus = INACTIVE;
    } else {
        activeStatus = ACTIVE;
    }

    if (
        (filterData?.roles &&
            !filterData.roles.includes(`${user.sub_role_id}`)) ||
        (filterData?.status && !filterData.status.includes(activeStatus))
    ) {
        return false;
    }
    return true;
};

export const mappingBySearchWord = (user: Authv1User, word: string) => {
    const username = (user?.first_name || "") + (user?.last_name || "");
    const wordWithoutSpaces = word.replace(/\s/g, "").toUpperCase();

    return (
        username.toUpperCase().includes(wordWithoutSpaces) ||
        user.email.toUpperCase().includes(wordWithoutSpaces)
    );
};

export const sortingFn = (
    user1: Authv1User,
    user2: Authv1User,
    sortBy: SortingInfo,
) => {
    const username1 =
        user1?.first_name && user1.last_name
            ? (user1?.first_name + user1.last_name).trim().toUpperCase()
            : user1.email.toUpperCase();
    const username2 =
        user2?.first_name && user2.last_name
            ? (user2?.first_name + user2.last_name).trim().toUpperCase()
            : user2.email.toUpperCase();

    let user1Status = "";
    let user2Status = "";

    if (user1.is_confirmed) {
        user1Status = user1.is_banned ? INACTIVE : ACTIVE;
    } else {
        user1Status = WAITING;
    }

    if (user2.is_confirmed) {
        user2Status = user2.is_banned ? INACTIVE : ACTIVE;
    } else {
        user2Status = WAITING;
    }

    if (sortBy.name === usersTableColumns.USERNAME) {
        return sortBy.order === SortingOrder.ASC
            ? username1.localeCompare(username2)
            : username2.localeCompare(username1);
    } else if (sortBy.name === usersTableColumns.ROLE) {
        return sortBy.order === SortingOrder.ASC
            ? String(user1?.sub_role_id).localeCompare(
                  String(user2?.sub_role_id),
              )
            : String(user2?.sub_role_id).localeCompare(
                  String(user1?.sub_role_id),
              );
    } else if (sortBy.name === usersTableColumns.STATUS) {
        return sortBy.order === SortingOrder.ASC
            ? user1Status.localeCompare(user2Status)
            : user2Status.localeCompare(user1Status);
    } else {
        if (!user1.created_at || !user2.created_at) {
            return 0;
        }

        const user1Date = new Date(user1.created_at);
        const user2Date = new Date(user2.created_at);

        if (user1Date < user2Date) {
            return 1;
        } else if (user1Date > user2Date) {
            return -1;
        }
        return 0;
    }
};

export const getUserData = (data?: V1CombinedUserItem[]) => {
    return (data || []).map((item) => ({
        ...item.profile,
        ...item.general,
    }));
};
