import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";

import { useChangeUserRoleMutation } from "lib/api/hooks/use-change-user-role-mutation";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { successToast } from "lib/utils/toast";
import { ModalFooter } from "components/molecules/modal/components/modal-footer/modal-footer.component";
import { ModalContent } from "./components/modal-content/modal-content.component";
import { ModalDesktopHeader } from "components/molecules/modal/components/modal-desktop-header/modal-desktop-header.component";
import { AdaptiveModal } from "components/molecules/modal/components/adaptive-modal/adaptive-modal.component";
import { ScrollableModalBody } from "components/molecules/modal/components/modal-body/scrollable-modal-body.component";
import { getSubRoleIdOrUndefined } from "lib/constants/not-assigned-sub-role";
import { useGetUserByIdQueryKey } from "lib/api/hooks/use-get-user-query.hook";
import { ApiErrorCode } from "lib/error-handler";
import { handleServerErrors } from "lib/utils/server-errors";
import {
    ChangeUserRoleModalProps,
    SUB_ROLE_NOT_FOUND_KEY,
    SUB_ROLE_NOT_FOUND_MESSAGE,
    testIds,
    TOAST_SUCCESS_MESSAGE,
    TOAST_SUCCESS_MESSAGE_KEY,
    USER_NOT_FOUND_KEY,
    USER_NOT_FOUND_MESSAGE,
    MODAL_TITLE,
} from "./change-user-role-modal.interface";

export const ChangeUserRoleModal = ({
    user,
    opened,
    onClose,
}: ChangeUserRoleModalProps) => {
    const { t } = useTranslation("users");

    const [selectedRole, setSelectedRole] = useState<string>();
    const queryClient = useQueryClient();

    const onCloseHandler = () => {
        setSelectedRole(undefined);
        onClose();
    };

    const { mutate, isLoading } = useChangeUserRoleMutation({
        onSuccess: async () => {
            successToast(t(TOAST_SUCCESS_MESSAGE_KEY, TOAST_SUCCESS_MESSAGE), {
                className: testIds.changeRoleSuccessToast,
            });
            await queryClient.invalidateQueries([useGetUsersQueryKey]);
            await queryClient.invalidateQueries([
                useGetUserByIdQueryKey,
                user?.id,
            ]);
            onCloseHandler();
        },
        onError: (error) => {
            handleServerErrors({
                t,
                error,
                customErrors: {
                    [ApiErrorCode.SELECTED_SUB_ROLE_NOT_FOUND]: {
                        message: t(
                            SUB_ROLE_NOT_FOUND_KEY,
                            SUB_ROLE_NOT_FOUND_MESSAGE,
                        ),
                        className: testIds.subRoleNotFoundErrorToast,
                        type: "error",
                    },
                    [ApiErrorCode.SELECTED_USER_NOT_FOUND]: {
                        message: t(USER_NOT_FOUND_KEY, USER_NOT_FOUND_MESSAGE),
                        className: testIds.userNotFoundErrorToast,
                        type: "error",
                    },
                },
            });
        },
    });

    const onSubmitHandler = () => {
        mutate({
            user_id: user?.id || "",
            body: { sub_role_id: getSubRoleIdOrUndefined(selectedRole) },
        });
    };

    const onUserRoleChangeHandler = (item: string) => {
        if (item) {
            setSelectedRole(item);
        }
    };

    return (
        <AdaptiveModal
            testId={testIds.changeUserRoleModal}
            height={320}
            opened={opened}
            onClose={onCloseHandler}
        >
            <ModalDesktopHeader
                title={t("title.changeTheRoleForUser", MODAL_TITLE)}
                onClose={onCloseHandler}
            />
            <ScrollableModalBody width={500}>
                {user && (
                    <ModalContent
                        title={t("title.changeTheRoleForUser", MODAL_TITLE)}
                        onUserRoleChange={onUserRoleChangeHandler}
                        user={user}
                    />
                )}
            </ScrollableModalBody>

            <ModalFooter
                firstButtonWidth={123}
                secondButtonWidth={87}
                isLoading={isLoading}
                firstButtonText={t("button.assignRole", "Assign a role")}
                onFirstButtonClick={onSubmitHandler}
                isFirstButtonDisabled={
                    user?.sub_role_id === selectedRole || !selectedRole
                }
                onSecondButtonClick={onCloseHandler}
                firstButtonTestId={testIds.assignButton}
                secondButtonTestId={testIds.cancelButton}
            />
        </AdaptiveModal>
    );
};

ChangeUserRoleModal.displayName = "ChangeUserRoleModal";
