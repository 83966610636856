import { Authv1User } from "lib/api/common/data-contracts";

export type ModalContentProps = {
    title: string;
    user: Authv1User;
    onUserRoleChange: (value: string) => void;
};

export const testIds = {
    chooseRoleDropdown: "choose-role-dropdown",
    option: "option",
};
