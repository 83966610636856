import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";

import { useDeleteNotConfirmedUserMutation } from "lib/api/hooks/use-delete-not-confirmed-user-mutation.hook";
import { WarningModal } from "components/molecules/warning-modal/warning-modal.component";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { BUTTONS_DIRECTION } from "components/molecules/warning-modal/warning-modal.constants";
import { successToast } from "lib/utils/toast";
import { handleServerErrors } from "lib/utils/server-errors";
import {
    DeleteNotConfirmedUserModalProps,
    testIds,
    DELETE_USER_KEY,
    OK_BUTTON_KEY,
    OK_BUTTON_MESSAGE,
    SUCCESS_TOAST_KEY,
    SUCCESS_TOAST_MESSAGE,
    TITLE_KEY,
    TITLE_MESSAGE,
    DELETE_USER_MESSAGE,
    CANCEL_BUTTON_KEY,
    CANCEL_BUTTON_MESSAGE,
} from "./delete-not-confirmed-user-modal.interface";

export const DeleteNotConfirmedUserModal = ({
    opened,
    onClose,
    userInfo,
}: DeleteNotConfirmedUserModalProps) => {
    const { t } = useTranslation("users");

    const queryClient = useQueryClient();

    const mutation = useDeleteNotConfirmedUserMutation({
        onSuccess: async () => {
            successToast(t(SUCCESS_TOAST_KEY, SUCCESS_TOAST_MESSAGE), {
                className: testIds.successToast,
            });

            await queryClient.invalidateQueries([useGetUsersQueryKey]);

            onClose();
        },
        onError: (error) => {
            handleServerErrors({ t, error });
        },
    });

    return (
        <WarningModal
            opened={opened}
            onClose={onClose}
            onCancel={onClose}
            testId={testIds.deleteNotConfirmedUserModal}
            title={t(TITLE_KEY, TITLE_MESSAGE)}
            message={t(DELETE_USER_KEY, DELETE_USER_MESSAGE, {
                username: userInfo?.username,
            })}
            onOk={() => userInfo && mutation.mutate(userInfo.id)}
            okButtonIsLoading={mutation.isLoading}
            okButtonVariant="outlined"
            okButtonText={t(OK_BUTTON_KEY, OK_BUTTON_MESSAGE)}
            cancelButtonText={t(CANCEL_BUTTON_KEY, CANCEL_BUTTON_MESSAGE)}
            cancelButtonVariant="primary"
            buttonsDirection={BUTTONS_DIRECTION.rowReverse}
            okButtonTestId={testIds.okButtonTestId}
            cancelButtonTestId={testIds.cancelButtonTestId}
        />
    );
};
