import {
    LENGTH_LIMIT_MAX_TRANSLATE_KEY,
    LENGTH_LIMIT_MIN_TRANSLATE_KEY,
    LENGTH_LIMIT_TRANSLATE_KEY,
    LENGTH_TRANSLATE_KEY,
} from "./constants";
import { LengthLimitValidatorParams } from "./types";

export const lengthLimitValidator: LengthLimitValidatorParams = ({
    validation,
    validationRule,
    t,
}) => {
    const { min, max } = validationRule;

    if (min && max) {
        if (min === max) {
            const message = t(LENGTH_TRANSLATE_KEY, "Enter {{x}} symbols.", {
                x: min,
            });

            return validation.min(min, message).max(max, message);
        }

        const message = t(
            LENGTH_LIMIT_TRANSLATE_KEY,
            "Enter {{min}} to {{max}} symbols.",
            { min, max },
        );

        return validation.min(min, message).max(max, message);
    } else if (min) {
        const message = t(
            LENGTH_LIMIT_MIN_TRANSLATE_KEY,
            "Enter at least {{min}} symbols.",
            { min },
        );

        return validation.min(min, message);
    } else if (max) {
        const message = t(
            LENGTH_LIMIT_MAX_TRANSLATE_KEY,
            "Enter up to {{max}} symbols.",
            { max },
        );

        return validation.max(max, message);
    }

    return validation;
};
